import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOffers from "@/data/AdminPanel/DataOffers";
import ApiEnter from "@/api/ApiEnter";
import ApiOffer from "@/api/ApiOffer";
import sweetalert from "sweetalert";
import ApiAdmin from "@/api/ApiAdmin";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@/data/CKeditor/config";
let OfferCreate = class OfferCreate extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.editorConfig = CKEditor.editorConfig(ApiEnter.CurrentSessionUUID);
        this.Breadcrumbs = DataOffers.OfferCreateBreadcrumbs;
        this.NewCategoryCardDesign = ["Blue", "Orange", "Transparent"];
        this.OffersCategories = [];
        this.ActiveOffers = []; // TODO: Уточнить почему жалуется на never
        this.ActiveOfferID = 0;
        this.ActiveOffer = null;
        this.newOffer = {
            name: "",
            priority: "",
            publish: true,
            category: [],
            price: "",
            data: "",
            sms: "",
            min: "",
            additional_data: "",
            int_min: "",
            countries: "",
            rates_abroad: false,
            free: false,
            activation_info: "",
            display_roaming: true,
            overview: "",
            note: "",
            design: "",
            photo_list: null,
            photo_slide_lg: null,
            photo_slide: null,
            photo_slide_m: null,
            display_offers: false,
            display_home: false,
            display_slider: false,
            active: "active",
            tariff_overview: "",
            prospects_info: null,
            contract: null,
            title: "",
            description: "",
            keywords: "",
            url: "",
        };
        this.HasLoading = false;
        this.LoadingDataFromBSS = true;
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    GenerateSeoUrl() {
        let url = this.Translit(this.newOffer.name.replace(/[\s]+/gi, "-"));
        this.newOffer.url = url.replace(/[^0-9a-z_-]+/gi, "").toLowerCase();
        // this.newOffer.url = this.newOffer.name.replace(/[. ,:-=&+#$?|%@!^(){}'*]+/g, "-").toLowerCase()
    }
    ValidateSeoUrl() {
        this.newOffer.url = this.Translit(this.newOffer.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
        // this.newOffer.url = this.newOffer.url.replace(/[. ,$@!^()'*]+/g, '-').toLowerCase()
    }
    async getOffersCategories() {
        try {
            this.OffersCategories = await ApiOffer.GetOfferCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const activeOffer = this.ActiveOfferID ? JSON.stringify(this.ActiveOffer) : "";
            const offer_uuid = await ApiOffer.CreateOffer(ApiEnter.CurrentSessionUUID, this.newOffer.name, this.newOffer.priority, this.newOffer.publish ? 1 : 0, this.newOffer.category.join(), this.newOffer.price, this.newOffer.data, this.newOffer.sms, this.newOffer.min, this.newOffer.additional_data, this.newOffer.int_min, this.newOffer.countries, this.newOffer.rates_abroad ? 1 : 0, this.newOffer.free ? 1 : 0, this.newOffer.activation_info, this.newOffer.display_roaming ? 1 : 0, this.newOffer.overview, this.newOffer.note, this.newOffer.design, this.newOffer.photo_list != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_list)) : "", this.newOffer.photo_slide != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide)) : "", this.newOffer.photo_slide_lg != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide_lg)) : "", this.newOffer.photo_slide_m != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide_m)) : "", this.newOffer.display_offers ? 1 : 0, this.newOffer.display_home ? 1 : 0, this.newOffer.display_slider ? 1 : 0, this.newOffer.active === "active" ? 1 : 0, this.newOffer.tariff_overview, this.newOffer.prospects_info != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info)) : "", this.newOffer.contract != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.contract)) : "", this.newOffer.title, this.newOffer.description, this.newOffer.keywords, this.newOffer.url, activeOffer, this.ActiveOfferID);
            if (offer_uuid == undefined || offer_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push(`/shop/offers`);
            });
        }
        this.HasLoading = false;
    }
    async GetActiveOffers() {
        const response = await ApiOffer.GetActiveOffers(ApiEnter.CurrentSessionUUID);
        if (typeof response != "boolean") {
            this.ActiveOffers = response;
            this.ActiveOffers.unshift({
                ID: null,
                Title: "No matching",
            });
        }
        else {
            sweetalert({
                title: this.$t("general.error"),
                text: "Error to get offers from BSS",
                icon: "info",
            });
        }
        this.LoadingDataFromBSS = false;
    }
    ChangeActiveOffer() {
        this.ActiveOffer = this.ActiveOffers.find(item => item.ID == this.ActiveOfferID);
    }
    async mounted() {
        await this.getOffersCategories();
        await this.GetActiveOffers();
    }
};
OfferCreate = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OfferCreate);
export default OfferCreate;
