import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiFaq from "@/api/ApiFaq";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import dayjs from "dayjs";
import CKEditorConfig from "@/data/CKeditor/config";
let EditQuestion = class EditQuestion extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/faq",
                exact: true,
                text: "CMS",
            },
            {
                to: "/faq",
                exact: true,
                text: "Support (FAQ)",
            },
            {
                text: "Questions",
                disabled: true,
            },
        ];
        this.HasLoading = false;
        this.FaqCategories = [];
        this.currentFaq = {
            answer: "",
            question: "",
            publish: true,
            priority: "",
            category: undefined,
            created: "",
        };
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const response = await ApiFaq.UpdateFaqInfo(this.currentFaq.answer, this.currentFaq.question, this.currentFaq.priority, this.currentFaq.publish ? 1 : 0, this.currentFaq.category, ApiEnter.CurrentSessionUUID, this.CurrentFaqUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$forceUpdate();
                    this.currentFaq.answer = "";
                    this.currentFaq.question = "";
                    this.currentFaq.priority = "";
                    this.currentFaq.publish = true;
                    this.currentFaq.category = "";
                    this.currentFaq.created = "";
                    this.$router.push(`/faq`);
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        this.HasLoading = false;
    }
    async GetFaqCategories() {
        try {
            this.FaqCategories = await ApiFaq.GetFaqCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async GetFaqInfo() {
        const faqInfo = await ApiFaq.GetFaqByUUID(ApiEnter.CurrentSessionUUID, this.CurrentFaqUUID);
        if (faqInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.push("/");
            });
            return;
        }
        this.currentFaq.answer = faqInfo.answer;
        this.currentFaq.question = faqInfo.question;
        this.currentFaq.publish = faqInfo.publish;
        this.currentFaq.priority = faqInfo.priority;
        this.currentFaq.created = dayjs(faqInfo.create_at).format("DD.MM.YYYY HH:mm");
        this.currentFaq.category = faqInfo.category;
    }
    mounted() {
        this.CurrentFaqUUID = this.$route.params.faq_uuid;
        this.GetFaqCategories();
        this.GetFaqInfo();
    }
};
EditQuestion = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, CKEditor },
    })
], EditQuestion);
export default EditQuestion;
