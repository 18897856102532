import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiEnter from "@/api/ApiEnter";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ApiShopOptions from "@/api/ApiShopOptions";
import sweetalert from "sweetalert";
import DataOptions from "@/data/AdminPanel/DataOptions";
import CKEditorConfig from "@/data/CKeditor/config";
let OptionsCreate = class OptionsCreate extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.Breadcrumbs = DataOptions.BreadcrumbsCreate;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.PeriodTypes = ["ora", "giorno", "settimana", "mese", "anno"];
        this.MeasureTypes = ["SMS", "Giga", "SIM", "Minuti", "Pezzo", "Servizio"];
        this.newOption = {
            name: "",
            priority: "",
            publish: true,
            type: "extra",
            price: "",
            period: "",
            value: "",
            measure: "",
            connect: "",
            link_for_button: "",
            limits: "",
            overview: "",
            note: "",
            bss: "",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            seo_url: "",
        };
        this.HasLoading = false;
    }
    GenerateSeoUrl() {
        let url = this.Translit(this.newOption.name.replace(/[\s]+/gi, "-"));
        this.newOption.seo_url = url.replace(/[^0-9a-z_-]+/gi, "").toLowerCase();
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    ValidateSeoUrl() {
        this.newOption.seo_url = this.Translit(this.newOption.seo_url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const option_uuid = await ApiShopOptions.CreateOption(ApiEnter.CurrentSessionUUID, this.newOption.name, this.newOption.priority, this.newOption.publish ? 1 : 0, this.newOption.type, this.newOption.price, this.newOption.period, this.newOption.value, this.newOption.measure, this.newOption.connect, this.newOption.link_for_button, this.newOption.limits, this.newOption.overview, this.newOption.note, this.newOption.bss, this.newOption.seo_title, this.newOption.seo_description, this.newOption.seo_keywords, this.newOption.seo_url);
            if (option_uuid == undefined || option_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push(`/shop/options`);
            });
        }
        this.HasLoading = false;
    }
};
OptionsCreate = __decorate([
    Component({
        components: { StandartTemplate, PageHeader, CKEditor },
    })
], OptionsCreate);
export default OptionsCreate;
