import axios from "axios";
class ApiBlog {
    static async GetBlog(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-blog", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async CreateBlog(session_uuid, title, date, publish, image1, image2, card_design, content, seo_description, seo_keywords, seo_url) {
        try {
            const result = await axios.post("/api/admin/create-blog", {
                title: title,
                date: date,
                publish: publish,
                image1: image1,
                image2: image2,
                card_design: card_design,
                content: content,
                seo_description: seo_description,
                seo_keywords: seo_keywords,
                seo_url: seo_url,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteBlog(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-blog", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetBlogByUUID(session_uuid, blog_uuid) {
        try {
            const result = await axios.post("/api/admin/get-blog-by", {
                blog_uuid: blog_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateBlogInfo(title, date, publish, image1, image2, card_design, content, seo_description, seo_keywords, seo_url, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-blog-info", {
                title: title,
                date: date,
                publish: publish,
                image1: image1,
                image2: image2,
                card_design: card_design,
                content: content,
                seo_description: seo_description,
                seo_keywords: seo_keywords,
                seo_url: seo_url,
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async UpdateBlogPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-blog-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiBlog;
