import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOffers from "@/data/AdminPanel/DataOffers";
import ApiEnter from "@/api/ApiEnter";
import ApiOffer from "@/api/ApiOffer";
import sweetalert from "sweetalert";
import ApiAdmin from "@/api/ApiAdmin";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@/data/CKeditor/config";
import UtilGeneral from "@/util/Common/UtilGeneral";
let OfferEdit = class OfferEdit extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.editorConfig = CKEditor.editorConfig(ApiEnter.CurrentSessionUUID);
        this.Breadcrumbs = DataOffers.OfferEditBreadcrumbs;
        this.NewCategoryCardDesign = ["Blue", "Orange", "Transparent"];
        this.OffersCategories = [];
        this.ActiveOffers = []; //
        this.ActiveOfferID = 0;
        this.ActiveOffer = null;
        this.newOffer = {
            name: "",
            priority: "",
            publish: true,
            category: [],
            price: "",
            data: "",
            sms: "",
            min: "",
            additional_data: "",
            int_min: "",
            countries: "",
            rates_abroad: true,
            free: true,
            activation_info: "",
            display_roaming: true,
            overview: "",
            note: "",
            design: "",
            photo_list: null,
            photo_list_name: "",
            photo_slide: null,
            photo_slide_lg: null,
            photo_slide_lg_name: null,
            photo_slide_name: "",
            photo_slide_m: null,
            photo_slide_m_name: "",
            display_offers: true,
            display_home: false,
            display_slider: false,
            active: "active",
            tariff_overview: "",
            prospects_info: null,
            prospects_info_name: "",
            contract: null,
            contract_name: "",
            is_porting: true,
            title: "",
            description: "",
            keywords: "",
            url: "",
        };
        this.HasLoading = false;
        this.LoadingDataFromBSS = true;
        this.IsNewFileData = {
            photo_list: false,
            photo_slide: false,
        };
    }
    async GetActiveOffers() {
        const response = await ApiOffer.GetActiveOffers(ApiEnter.CurrentSessionUUID);
        if (typeof response != "boolean") {
            this.ActiveOffers = response;
            this.ActiveOffers.unshift({
                ID: null,
                Title: "No matching",
            });
        }
        else {
            sweetalert({
                title: this.$t("general.error"),
                text: "Error to get offers from BSS",
                icon: "info",
            });
        }
        this.LoadingDataFromBSS = false;
    }
    async GetOfferInfo() {
        const offerInfo = await ApiOffer.GetOfferByUUID(ApiEnter.CurrentSessionUUID, this.CurrentOfferUUID);
        if (offerInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.newOffer.name = offerInfo.name;
        this.newOffer.priority = offerInfo.priority;
        this.newOffer.publish = offerInfo.publish;
        this.newOffer.category = offerInfo.category.split(",");
        this.newOffer.price = String(offerInfo.price);
        this.newOffer.data = offerInfo.data;
        this.newOffer.sms = offerInfo.sms;
        this.newOffer.min = offerInfo.min;
        this.newOffer.additional_data = offerInfo.additional_data;
        this.newOffer.int_min = offerInfo.int_min;
        this.newOffer.countries = offerInfo.countries;
        this.newOffer.rates_abroad = offerInfo.rates_abroad;
        this.newOffer.free = offerInfo.free;
        this.newOffer.activation_info = offerInfo.activation_info;
        this.newOffer.display_roaming = offerInfo.display_roaming;
        this.newOffer.overview = offerInfo.overview;
        this.newOffer.note = offerInfo.note;
        this.newOffer.design = offerInfo.design;
        this.newOffer.photo_list = offerInfo.photo_list
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.photo_list).then(response => {
                this.newOffer.photo_list = new File([new Blob([response.data])], offerInfo.photo_list.split("/")[8]);
            })
            : null;
        this.newOffer.photo_list_name = offerInfo.photo_list;
        this.newOffer.photo_slide = offerInfo.photo_slide
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.photo_slide).then(response => {
                this.newOffer.photo_slide = new File([new Blob([response.data])], offerInfo.photo_slide.split("/")[8]);
            })
            : null;
        this.newOffer.photo_slide_name = offerInfo.photo_slide;
        this.newOffer.photo_slide_lg = offerInfo.photo_slide_lg
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.photo_slide_lg).then(response => {
                this.newOffer.photo_slide_lg = new File([new Blob([response.data])], offerInfo.photo_slide_lg.split("/")[8]);
            })
            : null;
        this.newOffer.photo_slide_lg_name = offerInfo.photo_slide_lg;
        this.newOffer.photo_slide_m = offerInfo.photo_slide_m
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.photo_slide_m).then(response => {
                this.newOffer.photo_slide_m = new File([new Blob([response.data])], offerInfo.photo_slide_m.split("/")[8]);
            })
            : null;
        this.newOffer.photo_slide_m_name = offerInfo.photo_slide_m;
        this.newOffer.display_offers = offerInfo.display_offers;
        this.newOffer.display_home = offerInfo.display_home;
        this.newOffer.display_slider = offerInfo.display_slider;
        this.newOffer.active = offerInfo.active === 1 ? "active" : "non_active";
        this.newOffer.tariff_overview = offerInfo.tariff_overview;
        this.newOffer.prospects_info = offerInfo.prospects_info
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.prospects_info).then(response => {
                this.newOffer.prospects_info = new File([new Blob([response.data])], offerInfo.prospects_info.split("/")[8]);
            })
            : null;
        this.newOffer.prospects_info_name = offerInfo.prospects_info;
        this.newOffer.contract = offerInfo.contract
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.contract).then(response => {
                this.newOffer.contract = new File([new Blob([response.data])], offerInfo.contract.split("/")[8]);
            })
            : null;
        this.newOffer.contract_name = offerInfo.contract;
        this.newOffer.price_vodafone = offerInfo.price_vodafone;
        this.newOffer.price_others = offerInfo.price_others;
        this.newOffer.title = offerInfo.title;
        this.newOffer.description = offerInfo.description;
        this.newOffer.keywords = offerInfo.keywords;
        this.newOffer.url = offerInfo.url;
        this.ActiveOffer = JSON.parse(offerInfo.external_data);
        this.ActiveOfferID = offerInfo.external_id;
    }
    async getOffersCategories() {
        try {
            this.OffersCategories = await ApiOffer.GetOfferCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const activeOffer = this.ActiveOfferID ? JSON.stringify(this.ActiveOffer) : "";
            const response = await ApiOffer.UpdateOfferInfo(this.newOffer.name, this.newOffer.priority, this.newOffer.publish ? 1 : 0, this.newOffer.category.join(), this.newOffer.price, this.newOffer.data, this.newOffer.sms, this.newOffer.min, this.newOffer.additional_data, this.newOffer.int_min, this.newOffer.countries, this.newOffer.rates_abroad ? 1 : 0, this.newOffer.free ? 1 : 0, this.newOffer.activation_info, this.newOffer.display_roaming ? 1 : 0, this.newOffer.overview, this.newOffer.note, this.newOffer.design, this.newOffer.photo_list != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_list, this.newOffer.photo_list_name)) : "", this.newOffer.photo_slide != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide, this.newOffer.photo_slide_name)) : "", this.newOffer.photo_slide_lg != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide_lg, this.newOffer.photo_slide_lg_name)) : "", this.newOffer.photo_slide_m != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide_m, this.newOffer.photo_slide_m_name)) : "", this.newOffer.display_offers ? 1 : 0, this.newOffer.display_home ? 1 : 0, this.newOffer.display_slider ? 1 : 0, this.newOffer.active === "active" ? 1 : 0, this.newOffer.tariff_overview, this.newOffer.prospects_info != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info, this.newOffer.prospects_info_name)) : "", this.newOffer.contract != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.contract, this.newOffer.contract_name)) : "", this.newOffer.title, this.newOffer.description, this.newOffer.keywords, this.newOffer.url, ApiEnter.CurrentSessionUUID, this.CurrentOfferUUID, activeOffer, this.ActiveOfferID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    ValidateSeoUrl() {
        this.newOffer.url = this.Translit(this.newOffer.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async DownloadPhotoList() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer.photo_list_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.photo_list_name.split("/")[8];
            a.click();
        });
    }
    async DownloadPhotoSlide(field) {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer[field]).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.photo_slide_name.split("/")[8];
            a.click();
        });
    }
    async DownloadPhotoSlideM() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer.photo_slide_m_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.photo_slide_m_name.split("/")[8];
            a.click();
        });
    }
    async DownloadProspectsInfo() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.prospects_info_name.split("/")[8];
            a.click();
        });
    }
    async DownloadContract() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer.contract_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.contract_name.split("/")[8];
            a.click();
        });
    }
    ChangeActiveOffer() {
        this.ActiveOffer = this.ActiveOffers.find(item => item.ID == this.ActiveOfferID);
    }
    created() {
        this.CurrentOfferUUID = this.$route.params.offer_uuid;
        this.getOffersCategories();
        this.GetActiveOffers();
        this.GetOfferInfo();
    }
};
OfferEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OfferEdit);
export default OfferEdit;
