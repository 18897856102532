import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiOffer from "@/api/ApiOffer";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import dayjs from "dayjs";
import DataOffersCategories from "@/data/AdminPanel/DataOffersCategories";
import sweetalert from "sweetalert";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let OffersCategories = class OffersCategories extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOffersCategories.Breadcrumbs;
        this.TableHeaders = DataOffersCategories.TableHeaders;
        this.TableItems = [];
        this.isOpenDialog = false;
        this.isOpenEditDialog = false;
        this.newCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
        };
        this.currentCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
            uuid: "",
        };
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    OpenDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.newCategory.name = "";
        this.newCategory.priority = "";
        this.newCategory.publish = true;
        this.newCategory.created = "";
    }
    ToggleEditDialog() {
        this.isOpenEditDialog = !this.isOpenEditDialog;
    }
    async openEditDialog(uuid) {
        this.currentCategory.uuid = uuid;
        const categoryInfo = await ApiOffer.GetOfferCategoryByUUID(ApiEnter.CurrentSessionUUID, uuid);
        if (categoryInfo == undefined) {
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        this.ToggleEditDialog();
        this.currentCategory.name = categoryInfo.name;
        this.currentCategory.priority = categoryInfo.priority;
        this.currentCategory.publish = categoryInfo.publish;
        this.currentCategory.created = dayjs(categoryInfo.create_at).format("DD.MM.YYYY");
    }
    async getOffers() {
        try {
            this.TableItems = await ApiOffer.GetOfferCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async ChangeOfferCategoryPublish(item) {
        try {
            const response = await ApiOffer.UpdateOfferCategoryPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.getOffers();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    DeleteCategory(uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("offers.confirm_delete"),
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiOffer.DeleteOfferCategory(ApiEnter.CurrentSessionUUID, uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.getOffers();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    DialogToggle() {
        if (this.isOpenDialog) {
            this.newCategory.publish = false;
            this.newCategory.priority = "";
            this.newCategory.name = "";
            this.newCategory.created = "";
        }
        this.isOpenDialog = !this.isOpenDialog;
    }
    async CreateFaqCategory() {
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const file_uuid = await ApiOffer.CreateOfferCategory(ApiEnter.CurrentSessionUUID, this.newCategory.name, this.newCategory.priority, this.newCategory.publish ? 1 : 0);
            if (file_uuid == undefined || file_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            this.$forceUpdate();
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => this.OpenDialog());
            await this.getOffers();
        }
    }
    async ChangeFaqCategoryInfo() {
        try {
            const response = await ApiOffer.UpdateOfferCategoryInfo(this.currentCategory.name, this.currentCategory.priority, this.currentCategory.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, this.currentCategory.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.ToggleEditDialog();
                    this.getOffers();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    mounted() {
        this.getOffers();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], OffersCategories.prototype, "GetCurrentSessionUser", void 0);
OffersCategories = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OffersCategories);
export default OffersCategories;
