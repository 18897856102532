import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiBlog from "@/api/ApiBlog";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataBlogCreate from "@/data/AdminPanel/DataBlogCreate";
import dayjs from "dayjs";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
let Blog = class Blog extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/admin/blog",
                text: this.$t("menu.cms"),
            },
            {
                to: "/blog",
                text: this.$t("blog.blog"),
                disabled: true,
            },
        ];
        this.TableHeaders = DataBlogCreate.TableHeaders;
        this.TableItems = [];
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async GetBlogs() {
        try {
            this.TableItems = await ApiBlog.GetBlog(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async ChangeBlogPublish(item) {
        try {
            const response = await ApiBlog.UpdateBlogPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("blog.updated"),
                }).then(() => {
                    this.GetBlogs();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + response,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
        }
    }
    DeleteBlog(blog_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("blog.delete_post"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiBlog.DeleteBlog(ApiEnter.CurrentSessionUUID, blog_uuid);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.GetBlogs();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error") + response,
                        icon: "error",
                    });
                }
            }
        });
    }
    created() {
        this.GetBlogs();
    }
    formatDate(item) {
        return dayjs(item).format("DD.MM.YYYY");
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], Blog.prototype, "GetCurrentSessionUser", void 0);
Blog = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], Blog);
export default Blog;
