import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataFeedback from "@/data/CMS/DataFeedback";
import ApiFeedback from "@/api/ApiFeedback";
import ApiEnter from "@/api/ApiEnter";
import dayjs from "dayjs";
let FeedbackPartners = class FeedbackPartners extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/admin",
                text: this.$t("menu.cms"),
            },
            {
                to: "/admin",
                text: this.$t("feedback.feedback_messages"),
            },
            {
                to: "/",
                text: this.$t("feedback.become_a_partner"),
                disabled: true,
            },
        ];
        this.TableHeaders = DataFeedback.PartnersTableHeaders;
        this.TableItems = [];
    }
    formatDate(item) {
        return dayjs(item).format("DD-MM-YYYY (HH:mm:s)");
    }
    async GetMessages() {
        try {
            const response = await ApiFeedback.GetBecomeAPartnerMessages(ApiEnter.CurrentSessionUUID);
            if (response != undefined) {
                this.TableItems = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    mounted() {
        this.GetMessages();
    }
};
FeedbackPartners = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FeedbackPartners);
export default FeedbackPartners;
